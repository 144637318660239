<template>

  
<b-card 
        v-if="loading"
        align="center"
      >

      <b-row>

        <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: center;"
          >
            <h4 class="font-weight-bolder">
              Biografía y redes sociales
            </h4>
            <hr class="mb-2">

          </b-col>

          <b-col
          cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>



        
        </b-col>

        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          </b-col>

          <b-col
            cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          </b-col>

          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

          <b-skeleton width="100%"  height="70px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
            cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          >

          <b-skeleton width="100%"  height="10px"></b-skeleton>
          <b-skeleton width="100%"  height="30px"></b-skeleton>


          </b-col>

          <b-col
        cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          > </b-col>


          <b-col
          cols="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
          > 

          <b-skeleton width="100%"  height="20px"></b-skeleton>

          <table width="100%">
            <tr>
              <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
            </tr>
          </table>

        
        </b-col>

          <b-col
          cols="3"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >   </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          >

          <b-skeleton width="100%"  height="30px"></b-skeleton>
        
        </b-col>


        <b-col
        cols="4"
            sm="4"
            md="4"
            lg="4"
            xl="4"
          > </b-col>


     


          </b-row>

          </b-card>



      <b-card v-else-if="code200">

 

      <b-form
        autocomplete="off"
        @submit.prevent="onSubmit"
      >

        <b-row>

            <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: center;"
          >
            <h4 class="font-weight-bolder">
              Biografía y redes sociales
            </h4>
            <hr class="mb-2">

          </b-col>



        

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="margin-bottom: 10px;"
          >
               <p class="textLabel">
              Biografia (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'La biografía es una descripción corta sobre ti o sobre tu negocio para otorgar mayor información a tus clientes cuando realizen pagos en tus links de cobros.'"
                title="Biografía"
                style="margin-right: 5px;cursor:pointer;"
                /> 
            </p>

                 <b-form-textarea
                v-model="biografia"
                placeholder="Acerca de mi...."
                rows="3"
                no-resize
                required
                :readonly="isActive"
                :state="biografia.length <= maxChar"
                class="char-textarea"
                :class="biografia.length >= maxChar ? 'text-danger' : ''"
              />
              <small
                class="textarea-counter-value float-left"
                :class="biografia.length >= maxChar ? 'bg-danger' : ''"
              >
                <span class="char-count">{{ biografia.length }}</span> / {{ maxChar }}
              </small>

          </b-col>


           <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Facebook:
              </p>

              <b-input-group>
                <b-input-group-prepend is-text>
                   <feather-icon
                      icon="FacebookIcon"
                      size="18"
                      style="margin-right: 5px;"
                      /> 
                </b-input-group-prepend>
                <b-form-input
                  v-model="facebook"
                  :readonly="isActive"
                  type="text"
                 placeholder="Ingrese su usuario de Facebook"
                />
              </b-input-group>

            </b-col>

               <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Instagram:
              </p>

              <b-input-group>
                <b-input-group-prepend is-text>
                   <feather-icon
                      icon="InstagramIcon"
                      size="18"
                      style="margin-right: 5px;"
                      /> 
                </b-input-group-prepend>
                <b-form-input
                  v-model="instagram"
                  :readonly="isActive"
                  type="text"
                 placeholder="Ingrese su usuario de Instagram"
                />
              </b-input-group>

            </b-col>


            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Twitter:
              </p>

              <b-input-group>
                <b-input-group-prepend is-text>
                   <feather-icon
                      icon="TwitterIcon"
                      size="18"
                      style="margin-right: 5px;"
                      /> 
                </b-input-group-prepend>
                <b-form-input
                  v-model="twitter"
                  :readonly="isActive"
                  type="text"
                 placeholder="Ingrese su usuario de Twitter"
                />
              </b-input-group>

            </b-col>


            <b-col
              sm="12"
              md="12"
              lg="6"
              xl="6"
              style="margin-bottom: 10px;"
            >

              <p class="textLabel">
                Tiktok:
              </p>

              <b-input-group>
                <b-input-group-prepend is-text>
                   <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icons_colors"
              style="width: 23px;"
              widht="10"
              height="20"
              viewBox="0 0 448 512"
            ><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg>
                </b-input-group-prepend>
                <b-form-input
                  v-model="tiktok"
                  :readonly="isActive"
                  type="text"
                 placeholder="Ingrese su usuario de Tiktok"
                />
              </b-input-group>

            </b-col>

       

         

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
          >

            <p
              class="textLabel"
              style="text-align: center;margin-top: 10px; margin-bottom: 10px;"
            >
              Ingrese su código PIN (*): <feather-icon
                icon="HelpCircleIcon"
                size="16"
                  v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                title="Código PIN de seguridad"
                style="margin-right: 5px;cursor:pointer;"
                /> </p>

            <PincodeInput
              v-model="pin"
              placeholder="-"
              :length="6"
              :secure="true"
              required
              :autofocus="false"
            />

          </b-col>

          <b-col
            sm="12"
            md="12"
            lg="12"
            xl="12"
            align="center"
          >

            <b-button
              id="btn_presentacion"
              ref="btn_presentacion"
              type="submit"
              variant="primary"
              :disabled="isActive"
            >

              Actualizar datos

            </b-button>

          </b-col>

        </b-row>

      </b-form>
       </b-card>


      <b-card v-else align="center">
  <feather-icon
             icon="FrownIcon"
             size="50"
             />
      <p class="errorTexto">
        Ocurrió un error al cargar los datos
      </p>

      <b-button
        id="btn_refresh"
        ref="btn_refresh"
        variant="primary"
        @click="refreshGeneral"
      >

        Intentar de nuevo

      </b-button>

        </b-card>



 
</template>

<script>
import {
  BButton, BForm,BFormTextarea, BSkeleton, BFormInput, BRow,BInputGroupPrepend, VBPopover, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'


import PincodeInput from 'vue-pincode-input'

export default {
  components: {
    BButton,
    BFormTextarea,
    PincodeInput,
    BSkeleton,
    BForm,
    BFormInput,
    BInputGroupPrepend,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: ['userId','tokenAuth'],
  data() {
    return {
      biografia: '',
      maxChar: 250,
      isActive: false,
      pin: '',
      instagram:"",
      facebook:"",
      twitter:"",
      tiktok:"",
      loading: true,
      code200: false,

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {

    const userId_json = { userId: this.userId }

    const user_string = JSON.stringify(userId_json)

    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)

    this.$https.post('/profile/getBiografia/', { tokenAuth: this.tokenAuth, userId: dataEncripted }).then(response => {
      if (response.data.code == 200) {
        this.loading = false

        const { datos } = response.data

        this.biografia = datos.biografia.trim()

        this.facebook = datos.facebook

        this.twitter = datos.twitter



        this.tiktok = datos.tiktok




        this.instagram = datos.instagram



        this.code200 = true
      } else {
        this.$toast.error(response.data.message, {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })

        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.code200 = false
        }
      }
    }).catch(error => {
      this.$toast.error("Ocurrió un error inesperado", {
        position: 'top-right',
        timeout: 3010,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    })
  },
  methods: {
    refreshGeneral() {
      this.$eventBus.$emit('reiniciarPresentacion')
    },

    onSubmit(event) {
      event.preventDefault()

      if(this.biografia.length > this.maxChar){

              this.$toast.error("El texto de la biografía sobrepasa el límite permitido", {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

      }else{

          const userId_json = {
        userId: this.userId, pin: this.pin, facebook: this.facebook, instagram: this.instagram, twitter: this.twitter, tiktok: this.tiktok
      }

         const userId_json2 = {
        biografia: this.biografia
      }


      const user_string = JSON.stringify(userId_json)
  const user_string2 = JSON.stringify(userId_json2)


      const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
       const dataEncripted2 = this.$encryptBackoffice.encrypt(user_string2)


      this.isActive = true

      document.getElementById('btn_presentacion').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Actualizando perfil'

      this.$https.post('/profile/updateBiografia/', { tokenAuth: this.tokenAuth, data: dataEncripted, data2: dataEncripted2 }).then(response => {
        this.isActive = false
         this.pin="";
        document.getElementById('btn_presentacion').innerHTML = 'Actualizar datos'

        if (response.data.code == 200) {
          

              this.$toast.success(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          });

          if(Number(response.data.eqpoints) > Number("0")){

            
          this.eqcoins(response.data.eqpoints, "registrar una biografía a tu perfil", "");
          this.$eventBus.$emit('reiniciarNotificacionesNavBar');

          }



        } else {
          this.activarCampo = false

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })

          if (response.data.code == 401) {
            
            localStorage.removeItem('userData')



            
            

           

            
            

            // Redirect to login page
            this.$router.push({ name: 'auth-login' })
          } else if (response.data.code == 503) {
            this.pin = ''
          }
        }
      }).catch(error => {
        this.$toast.error("Ocurrió un error inesperado", {
          position: 'top-right',
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: 'button',
          icon: true,
          rtl: false,
        })
      })

      }

    
    },

  },
}
</script>
